const initiaState = {
    isCreatingDevices: false,
    deviceBeingUpdated: null,
    deviceBeingDeleted: null,
    device_count: 0,
    domain: 0,
    isReadingDomain: false,
    
    locations: [],
    locationsCount: 0,
    isReadingLocations: false,
    isCreatingLocations: false,
    isUpdatingLocation: false,
    isDeletingLocation: false,
    isValidatingLocation: false,
    isValidLocation: false,
    locationSuggestion: {},

    softphone: null,
    softphones: [],
    isReadingSoftphone: null,
    isReadingSoftphones: false,
    isCreatingSoftphones: false,
    isUpdatingSoftphone: false,
    isDeletingSoftphone: false,

    hardphone: null,
    isReadingHardphone: false,
    isUpdatingHardphone: false,
    isDeletingHardphone: false,

    isReadingCreds: false,
    creds: {},
    devicesCount: 0,
    isReadingDevices: false,
    devices: []
};

export default initiaState;
