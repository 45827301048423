import types from "actions/office_view/types";
import callTypes from "actions/call/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

// Functions for normal calls. 
//  threeway
//  warm-transfers will be different
//  Only deal with one leg at a time to avoid race conditions?
//  WE MIGHT WANT TO CHANGE THIS TO HAVE VALUES INSTEAD OF JUST IDS
/*
const ensureLeg = (state, leg, call) => {
    const l = call.leg.find(lg => lg.uuid === leg);
    if (l.extension) {
        if (state.extensions[l.extension]) {
            state.extensions[l.extension] = [];
        }
        if (! state.extensions[l.extension].find(c => c.uuid === call.uuid)) {
            state.extensions[l.extension].push(call.uuid);
        }
        if (l.extension === state.creds.extension) {
            if (! state.current_call) {
                state.current_call = call.uuid;
            }
        }
    }
}
*/

const newCall = (state, leg, call) => {
    const contactLeg = call.legs.find(l => ! l.extension);
    state.contactLeg = contactLeg.channel_uuid;
    const userLeg = call.legs.find(l => l.extension === state.creds.extension);
    if (userLeg) {
        state.userLeg = userLeg.channel_uuid;
        state.currentCall = call.uuid;
        state.currentPBX = call.pbx;
        state.pbx = call.pbx;
    }
    /*
    if (state.calls[call.uuid]) {
        return state;
    } else if (state.calls['_' + call.sip_call_id]) {
        state.calls[call.uuid] = call;
        delete state.calls['_' + call.sip_call_id]
        if (state.current_call === '_' + call.sip_call_id) {
            state.current_call = call.uuid;
        }
    } else {
        state.calls[call.uuid] = call;
    }
    ensureLeg(state, leg, call);
    */
    return state;
};

const connectedCall = (state, leg, call) => {
    /*
    if (state.calls[call.uuid]) {
        state.calls[call.uuid].connected = call.connected;
        state.calls[call.uuid].status = call.status;
    } else if (state.calls['_' + call.sip_call_id]) {
        state.calls[call.uuid] = call;
        delete state.calls['_' + call.sip_call_id]
        if (state.current_call === '_' + call.sip_call_id) {
            state.current_call = call.uuid;
        }
    } else {
        state.calls[call.uuid] = call;
    }
    ensureLeg(state, leg, call);
    */
    return state;
};
// Remove call ???
const endCall = (state, legUUID, call) => {
    if (state.current_call !== call.uuid) {
        return state;
    }
    const leg = call.legs.find(l => l.channel_uuid === legUUID);
    if (call.connected || leg.extension === state.creds.extension || !leg.extension) {
        delete state.contactLeg;
        delete state.userLeg;
        delete state.current_call;
        delete state.current_pbx;
    }
    return state;
    /*
    if (call.legs[legUUID].extension) {
        // User Hungup
        const idx = state.extensions[call.legs[legUUID].extension].findIndex(uuid => uuid === call.uuid)
        state.extensions[call.legs[legUUID].extension].splice(idx, 1);
        if (call.legs[legUUID].extension === state.creds.extension && state.current_call === call.uuid) {
            // Do something here
        } else {

        }
    } else {
        // Contact Hungup
        // Check for call type threeway or warm transfer latter ...
        Object.values(state.extensions).forEach(v => {
            const idx = v.findIndex(uuid => uuid === legUUID)
            v.splice(idx,1)
        });
        if (call.legs[legUUID].extension === state.creds.extension && state.current_call === call.uuid) {
            // Do something here
        } else {

        }

    }
    */
};

function officeViewReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingOfficeView: true };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingOfficeView: false,
                numberOfConferenceRooms: action.data.conference_rooms,
                departments: action.data.departments,
                extensions: action.data.extensions,
                queues: action.data.queues
            };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingOfficeView: false };

        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCalls: true };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCalls: false, calls: action.data };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCalls: false };

        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecentCalls: true };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingRecentCalls: false, recentCalls: action.data };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecentCalls: false };

        case types.ASSIGN_INCOMING_CALL_TO_QUEUE:
            return {
                ...state,
                queues: action.queues,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                queues: action.queues
            }

        case types.CALL_REQUEST:
            return { ...state, isCalling: true }
        case types.CALL_SUCCESS:
            return {
                ...state,
                incomingCalls: action.incomingCalls,
                callerInActionsView: action.contact || {},
                isActiveCall: true,
                actionsViewVisible: true,
                isCalling: true
            };
        case types.CALL_FAILURE:
            return { ...state, isCalling: false }

        case types.CLICK_RECENT_CALL:
            return {
                ...state,
                callerInActionsView: action.contact,
                actionsViewVisible: true,
                isActiveCall: false
            }

        case types.CLOSE_ACTIONS_VIEW:
            return { ...state, actionsViewVisible: false }

        case types.CLICK_ACTIVE_CALLER:
            return {
                ...state,
                callerInActionsView: action.contact,
                isActiveCall: true,
                actionsViewVisible: true
            }

        case types.REORDER_CALL_QUEUES:
            return {
                ...state,
                queues: action.queues
            }

        case types.HANGUP:
            return {
                ...state,
                isActiveCall: false,
                actionsViewVisible: false,
                callerInActionsView: null
            }

        case callTypes.NEW_CALL:
            console.log("NEWCALL", action);
            return newCall(state, action.leg, action.call)
        case callTypes.END_CALL:
            console.log("ENDCALL", action);
            return endCall(state, action.leg, action.call)
        case callTypes.CONNECT_CALL:
            console.log("CONNECTCALL", action);
            return connectedCall(state, action.leg, action.call)

        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCreds: true };
        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCreds: false, creds: action.data };
        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCreds: false, creds: {} };

        default:
            return state;
    }
}

export default officeViewReducer;
